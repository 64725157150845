import {
  MicrophoneIcon,
  TruckIcon,
  ScaleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'

const features = [

  {
    name: 'Tailor made lessons',
    description: 'Experience personalized lessons designed to meet your unique goals and learning style. Progress at your own pace with customized content for maximum effectiveness.',
    icon: TruckIcon,
  },

  {
    name: 'Hybrid Learning',
    description: 'Blended offline and online classes are available, allowing learners to maintain their routine while minimizing commuting.',
    icon: ScaleIcon,
  },

  {
    name: 'Group size',
    description: 'We offer personalized one-on-one and buddy classes for IELTS and TOEFL preparation, ensuring focused attention and effective training. With limited enrollment in small batches, young learners receive tailored support to excel in their language.',
    icon: UserGroupIcon,
  },

  {
    name: 'Community support',
    description: 'Join a supportive learning community that fosters collaboration and shared growth. Gain motivation, exchange ideas, and build lasting connections with like-minded individuals.',
    icon: MicrophoneIcon,
  },

  
 
]

export default function Featureex() {
  return (
    <div id="features" className="bg-indigo-700">
      <div className="mx-auto max-w-4xl px-6 py-24 sm:py-32 lg:max-w-7xl lg:px-8 lg:py-40">
        <h2 className="text-4xl font-bold tracking-tight text-white">Features of our program</h2>
        <p className="mt-6 max-w-3xl text-lg leading-8 text-indigo-200">
        Our expert techniques transform communication challenges into opportunities, helping learners become confident and impactful speakers, ready for success in public speaking and Cambridge exams.        </p>
        <div className="mt-20 grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name}>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">
                  <feature.icon aria-hidden="true" className="h-8 w-8 text-white" />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-semibold leading-8 text-white">{feature.name}</h3>
                <p className="mt-2 text-base leading-7 text-indigo-200">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
