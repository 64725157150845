import prod1 from'./assets/girlsgroupic.jpg'
import prod2 from'./assets/arjun.jpg'
import prod3 from'./assets/subashniprag3.jpg'

const people = [
    {
      name: 'Public Speaking',
      role: '6 to 14 years',
      imageUrl:prod3,
      bio: 'This program is tailored for beginners who are eager to learn English through friendly and effective methods based on global practices. It is suitable for young learners at any level and is customized to ensure you don’t relearn what you already know. ',
      xUrl: '#',
      linkedinUrl: '#',
    },
    {
        name: 'Cambridge english exam preparation',
        role: '6 to 15 years',
        imageUrl:prod1,
        bio: 'Prepare effectively for Cambridge English exams with expert guidance. Strengthen your language skills in reading, writing, listening, and speaking to achieve success and confidence in your test.',
        xUrl: '#',
        linkedinUrl: '#',
      },
      {
        name: 'Pronunciation and Super reader',
        role: '6 to 15 years',
        imageUrl:prod2,
        bio: 'Improve your English pronunciation and phonics through interactive exercises. Build vocabulary, refine grammar, and master clear, confident speech for greater impact and communication success.',
        xUrl: '#',
        linkedinUrl: '#',
      },
    // More people...
  ]

  export default function CourseexMini() {
    return (
      <div id="coursesmini" className="bg-indigo-100 py-24 md:py-32 lg:py-40">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          {/* Header Section */}
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-8 mb-12">
            <div className="xl:col-span-2 max-w-2xl">
              <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">I Can Speak Mini </h2>
              <h4 className="text-2xl font-bold tracking-tight text-gray-700 sm:text-2xl mt-3"> Programs </h4>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Our premium English language training program features a structured curriculum meticulously designed to equip you with the skills needed to become a fluent and confident English speaker.
              </p>
            </div>
            {/* Optional third column for design or empty */}
            <div className="hidden xl:block"></div>
          </div>
  
          {/* Courses Section */}
          <ul
            role="list"
            className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-12"
          >
            {people.map((person) => (
              <li key={person.name} className="flex flex-col">
                <div className="relative inset-0 flex-none rounded-2xl overflow-hidden">
                  <img
                    alt=""
                    src={person.imageUrl}
                    className="aspect-[3/2] w-full rounded-2xl object-cover overflow-hidden"
                  />
                  <div className="absolute inset-0 bg-indigo-400 rounded-2xl overflow-hidden mix-blend-multiply" />
                </div>
                <h3 className="mt-6 text-lg font-semibold leading-8 text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 font-semibold text-gray-600">{person.role}</p>
                <p className="mt-4 leading-7 text-gray-600">{person.bio}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }