import './App.css';
import Heroex from './components/Heroex';
import Featureex from './components/Featureex';
import Calltoactionex from './components/Calltoactionex';
import Statex from './components/Statex';
import Testimonialex from './components/Testimonialex';
import Teamex from './components/Teamex';
import Faqex from './components/Faqex';
import Footerex from './components/Footerex';
import Contactex from './components/Contactex';
import CourseexMini from './components/CourseexMini';
import CourseexPro from './components/CourseexPro';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Blogpage from './components/Blogpage';
import Bloglist from './components/Bloglist';
function App() {
  return (
    <>
    <Heroex/>
    <CourseexMini/>
    <CourseexPro/>
    <Featureex/>
    <Calltoactionex/>
    <Router>
    <Routes>
      <Route path="/" element={<Bloglist/>}/>
      <Route path="/blog/:id" element={<Blogpage/>}/>
    </Routes>
    </Router>
    <Statex/>
    <Testimonialex/>
    <Teamex/>
    <Faqex/>
    <Contactex/>
    <Footerex/>
    </>
  );
};
export default App;