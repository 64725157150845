import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "Is 'I Can Speak Pro' an online-only program?",
    answer:
      "Yes! With one-on-one and buddy classes, you’ll receive personalized attention and tailored lessons. Thanks to technology, all four modules are seamlessly integrated online, delivering incredible results.",
},

{
  question: "Will there be mock tests before the exam for ‘I Can Speak Pro’?",
  answer:
    "Yes, mock tests are an integral part of our program and will be conducted throughout the duration of your training.",
},

  {
    question: "How 'i can speak mini' is different from my regular school english class?",
    answer:
      "Unlike regular school English classes, this program won’t delve into in-depth grammar rules but will focus more on speaking skills needed for fluency, offering smaller class sizes for personalized attention and prioritizing practical sessions over theoretical lessons.",
  },
  {
      question: "What is the duration of each course in 'i can speak mini'?",
      answer:
        "The duration of each course is tailored to the individual’s proficiency level. Each term lasts 3 months, with a review meeting at the end of the term to discuss progress and provide feedback.",
    },
    {
        question: "How will my classes be? what will be focused in 'i can speak mini'?",
        answer:
          "I Can Speak Mini offers interactive, tailored sessions focusing on pronunciation, phonics, vocabulary, grammar, and clear communication to build fluency and confidence.",
    },
    
  {
    question: "Do you offer Franchising opputunities? ",
    answer:
      "Yes, we offer personal and center licensing only for 'I Can Speak Mini', exclusively to talented and passionate women dedicated to upholding our exceptional quality standards.",
  },
  {
    question: " I’m passionate about becoming an entrepreneur. Will I receive training to get started?",
    answer:
      "Absolutely! Once you join our franchise program, we provide detailed, in-person training to ensure you’re fully equipped for success.",
  },
{
  question: "Is there a royalty or monthly sharing fee for the franchise?",
  answer:
    "There is no royalty or monthly fee; the franchise requires a one-time payment. A minimum renewal fee will be applicable when the franchise licence is renewed.",
},

  // More questions...
]

export default function Faqex() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
