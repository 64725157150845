'use client'

import { useState, useEffect } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from './assets/ICCS5.gif';
import heropic1 from './assets/hasiq3.jpg';
import heropic2 from './assets/ukflag.jpg';
import heropic3 from './assets/toefl.png';

const navigation = [
  { name: 'Our Programs', href: '#coursesmini' },
  { name: 'Why Choose Us', href: '#features' },
  { name: 'Blogs', href: '#blog' },
  { name: 'Contact Us', href: '#contact' },
];

const heroImages = [heropic1, heropic2,heropic3];

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-gray-900">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-2 lg:px-4" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="m-0 p-0">
              <img alt="Company Logo" src={logo} className="h-44 w-auto" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-lg font-semibold leading-6 text-white"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden me-4 lg:flex lg:flex-1 lg:justify-end">
            <a
              href="tel:+919750921166"
              className="text-md font-bold leading-6 text-white"
            >
              +91 97509 21166
            </a>
          </div>
        </nav>

  <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
  <div className="fixed inset-0 z-50" />
  <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-4 py-4 sm:max-w-sm sm:ring-1 sm:ring-white/10">
    <div className="flex items-center justify-between">
      <a href="#" className="m-0 p-0">
        <span className="sr-only">Your Company</span>
        <img
          alt=""
          src={logo}
          className="h-44 w-auto px-0"
        />
      </a>
      <button
        type="button"
        onClick={() => setMobileMenuOpen(false)}
        className="-m-2.5 rounded-md p-2.5 text-gray-400"
      >
        <span className="sr-only">Close menu</span>
        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
      </button>
    </div>
    <div className="mt-6 flow-root">
      <div className="-my-6 divide-y divide-gray-500/25">
        <div className="space-y-2 py-6">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              onClick={() => setMobileMenuOpen(false)} 
              className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="py-6">
          <a
            href="tel:+919750921166"
            onClick={() => setMobileMenuOpen(false)} 
            className="-mx-3 flex items-center rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
          >
            +91 97509 21166
          </a>
        </div>
      </div>
    </div>
  </DialogPanel>
</Dialog>
      </header>

      <div className="relative isolate overflow-hidden pt-14">
        <img
          alt="Hero"
          src={heroImages[currentImageIndex]}
          className="absolute inset-0 h-full w-full object-cover"
        />
        <div className="absolute inset-0 bg-indigo-400 mix-blend-multiply" />
        <div className="relative text-center mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <h1 className="text-4xl font-bold tracking-wide text-white sm:text-6xl">
            Enhance Your English Fluency Effortlessly.
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Unleash your English-speaking potential. Learn effortlessly and become a confident
            speaker with our unique methodology.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://wa.me/+919750921166"
              className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-indigo-400"
            >
              Book Your Slot
            </a>
            <a href="#courses" className="text-sm font-semibold leading-6 text-white">
              Learn More →
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}