import prod1 from'./assets/ielts.jpg'
import prod2 from'./assets/toefl1.jpg'
import prod3 from'./assets/general2.jpg'

const people = [

    {
        name: ' IELTS exam preparation',
        role: '15 to 24 years',
        imageUrl:prod1,
        bio: 'Prepare effectively for the IELTS exam with expert guidance on all four modules. Build confidence, improve language skills, and master strategies to achieve your target band score.',
        xUrl: '#',
        linkedinUrl: '#',
      },
      {
        name: 'TOEFL exam preparation',
        role: '15 to 24 years',
        imageUrl:prod2,
        bio: 'Prepare for the TOEFL exam with focused training designed to improve your reading, writing, listening, and speaking skills. Build the confidence and strategies needed to achieve your desired score for academic and professional opportunities.',
        xUrl: '#',
        linkedinUrl: '#',
      },
      {
        name: 'Public Speaking and General English',
        role: '15 to 24 years',
        imageUrl:prod3,
        bio: 'Enhance your English communication and public speaking skills with practical exercises. Build confidence, improve fluency, and master the art of effective speaking for personal and professional success.',
        xUrl: '#',
        linkedinUrl: '#',
      },
    // More people...
  ]

  export default function CourseexPro() {
    return (
      <div id="coursespro" className="bg-indigo-200 py-24 md:py-32 lg:py-40">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          {/* Header Section */}
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-8 mb-12">
            <div className="xl:col-span-2 max-w-2xl">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">I Can Speak Pro</h2>
              <h4 className="text-2xl font-bold tracking-tight text-gray-700 sm:text-2xl mt-3"> Programs </h4>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Take your English skills to the next level with expert-led online one-on-one and buddy programs for IELTS, TOEFL prep, and impactful public speaking. Designed to make you fluent, fearless, and unstoppable!              
                </p>
            </div>
            {/* Optional third column for design or empty */}
            <div className="hidden xl:block"></div>
          </div>
  
          {/* Courses Section */}
          <ul
            role="list"
            className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-12"
          >
            {people.map((person) => (
              <li key={person.name} className="flex flex-col">
                <div className="relative inset-0 flex-none rounded-2xl overflow-hidden">
                  <img
                    alt=""
                    src={person.imageUrl}
                    className="aspect-[3/2] w-full rounded-2xl object-cover overflow-hidden"
                  />
                  <div className="absolute inset-0 bg-indigo-400 rounded-2xl overflow-hidden mix-blend-multiply" />
                </div>
                <h3 className="mt-6 text-lg font-semibold leading-8 text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 font-semibold text-gray-600">{person.role}</p>
                <p className="mt-4 text-base leading-7 text-gray-600">{person.bio}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }