import teamimg1 from './assets/arthi4.jpg'
import teamimg2 from './assets/laksh.jpg'
const people = [
  {
    name: 'Mrs.Arthi',
    role: 'Trainer',
    imageUrl:teamimg1, 
    bio: "Graduate from Ohio, USA, Entrepreneur, and Certified British Council Trainer with over 14 years of experience in shaping young minds.",
  },
  {
    name: 'Mrs.Lakshmi',
    role: 'Trainer',
    imageUrl:teamimg2, 
    bio: 'Dedicated English educator and Entrepreneur, in Melbourne, Australia, with 12 years of experience in helping students excel in language skills.',
  },
  // More people...
]

export default function Example() {
  return (
    <div id="team" className="bg-indigo-100 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Team</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We’re a dynamic group of individuals who are passionate about what we do and dedicated in delivering the
            best results at shorter time.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
        >
          {people.map((person) => (
            <li key={person.name} className=" flex flex-col gap-6 xl:flex-row">
              <div className='relative w-[300px] h-[400px] flex-none rounded-2xl overflow-hidden'>
              <img
              alt=""
              src={person.imageUrl}
              style={{ width: '300px', height: '400px' }}
              className="flex-none rounded-2xl object-cover overflow-hidden"
        />
              {/* <img alt="" src={person.imageUrl} className="w-80 h-80 flex-none rounded-2xl object-cover !w-75 !h-80" /> */}
              <div className="absolute inset-0 bg-indigo-300 mix-blend-multiply" />

              </div>
              <div className="flex-auto flex flex-col justify-center items-start text-start">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 text-gray-600">{person.role}</p>
                <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
              </div>

            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
